.slick-list {
  margin: 0px 20px !important;
}

.slick-arrow {
  margin: 0px 20px !important;
}

.slick-prev:before {
  content: '←';
  color: #fff !important;
  background: radial-gradient(#8430ca 50%, transparent 50%);
}

.slick-next:before {
  content: '→';
  color: #fff !important;
  background: radial-gradient(#8430ca 50%, transparent 50%);
}
.slick-slide.slick-active.slick-center.slick-current {
  transform: scale(1.1);
  transition: 0.5s all;
}

.slick-track {
  margin: 20px 0px;
}

.slick-slide > div > div > img {
  display: block;
}

@media (max-width: 1040px) {
  .slick-list {
    margin: 0px 0px !important;
  }

  .slick-arrow {
    margin: 0px 0px !important;
  }

  .slick-prev:before {
    display: none !important;
  }

  .slick-next:before {
    display: none !important;
  }
}
